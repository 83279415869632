import React, { memo } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useHistory, Link } from "react-router-dom";

//Components
import MyLoader from "components/ContentLoader";

import {
  LayoutWrapper,
  ContentBox,
  SmallInfoLabel,
} from "components/Layout/Index";

import ReportChart from "components/ReportChart";
import FaqItem from "components/FAQ";
import LiveMap from "components/Details/LiveMap";
import HomePageTour from "components/Tour/Homepage";

//Assets
import brazilFinancesIcon from "assets/images/finances/brazil-finances.svg";
import globalFinancesIcon from "assets/images/finances/global-finances.svg";
import selectArrowFinancesIcon from "assets/images/finances/arrow.svg";

import emailIcon from "assets/images/bottom/email.svg";
import chatIcon from "assets/images/bottom/chat.svg";

//Styles
import * as indexStyled from "../../styles/index-styles";
import * as styled from "./styles";

//Types
import {
  RecentOrder,
} from "./types";

//Hooks
import { useHome } from "./hooks";

import { useSettings } from "../../store/settings";

function Home({ viewAsRef, requestPaymentRef, settingsRef, testDataRef }: any) {
  const {
    showTour,
    setShowTour,
    homeRef,
    overviewRef,
    last5OrdersRef,
    liveMapRef,
    balanceRef,
    balanceViewAllRef,
    helpRef,
    accessLevel,
    recentOrders,
    currencyStore,
    // isForeigner,
    isRecentOrdersLoaded,
    recentOrdersAddress,
    setShowFinanceAccounts,
    showFinanceAccounts,
    selectedFinances,
    handleShowFinancesAccounts,
    financeAccountsRef,
    balances,
  } = useHome();

  const history = useHistory();
  const intl = useIntl();

  const session = JSON.parse(localStorage.getItem("session") || "{}");

  const { environment, isForeigner } = useSettings((state) => state.settings); 
  
  const validBusinessIds = [3739, 110]

  const viewOnlyAndAgent = 
  ( session.role === 'Store.Agent' || 
    session.role === 'Store.ViewOnly' || 
    session.role === 'Business.ViewOnly' || 
    session.role === 'Business.Agent') && validBusinessIds.includes(Number(session.businessId));

  if (viewOnlyAndAgent) {
    history.push("/transactions")
  }

  return (
    <>
      {showTour && (
        <HomePageTour
          tipSide="top"
          handleShowTour={setShowTour}
          refElement={[
            homeRef,
            viewAsRef,
            overviewRef,
            last5OrdersRef,
            liveMapRef,
            requestPaymentRef,
            balanceRef,
            balanceViewAllRef,
            settingsRef,
            testDataRef,
            helpRef,
          ]}
          stepsQuantity={11}
          intlId={"homepage"}
        />
      )}

      <LayoutWrapper refs={homeRef}>
        {accessLevel !== 2 ? (
          <ReportChart
            refs={overviewRef}
            recentOrders={recentOrders}
            currencyStore={currencyStore}
          />
        ) : null}

        <styled.RowWith2GridColumns
          className={accessLevel !== 2 ? `border-top` : ""}
        >
          <ContentBox refs={last5OrdersRef}>
            <indexStyled.TableList>
              <div className="title">
                <FormattedMessage id="home.recent-orders" />

                <p
                  onClick={() => history.push("/transactions")}
                  className="view-all__element"
                >
                  {intl.formatMessage({ id: "notification.item.view.all" })}
                </p>
              </div>

              <indexStyled.TableListHeader>
                <ul>
                  <li>
                    <FormattedMessage id="home.status" />
                  </li>
                  <li>
                    <FormattedMessage id="home.brl.amount" />
                  </li>
                  {/* {isForeigner && (
                    <li>
                      {currencyStore}{" "}
                      <FormattedMessage id="home.new.currency.amount" />
                    </li>
                  )} */}
                  <li>
                    <FormattedMessage id="home.name" />
                  </li>
                  <li>
                    <FormattedMessage id="admin.order.list.store.name" />
                  </li>

                  <li>
                    <FormattedMessage id="home.date" />
                  </li>
                </ul>
              </indexStyled.TableListHeader>
              <indexStyled.TableItems>
                {isRecentOrdersLoaded ? (
                  recentOrders.map((order: RecentOrder, key: number) => {
                    let status;
                    switch (order.payment.status) {
                      case "Pending":
                        status = (
                          <li>
                            <span className="status-pending">
                              <FormattedMessage id="payment.pending" />
                            </span>
                          </li>
                        );
                        break;
                      case "PreAuthorized":
                        status = (
                          <li>
                            <span className="status-pending">
                              <FormattedMessage id="status.pre.authorized" />
                            </span>
                          </li>
                        );
                        break;
                      case "Succeded":
                        status = (
                          <li>
                            <span className="status-succeeded">
                              <FormattedMessage id="payment.confirmed" />
                            </span>
                          </li>
                        );
                        break;
                      case "Canceled":
                      case "Expired":
                        status = (
                          <li>
                            <span className="status-failed">
                              <FormattedMessage id="payment.canceled" />
                            </span>
                          </li>
                        );
                        break;
                      case "Failed":
                        status = (
                          <li>
                            <span className="status-failed">
                              <FormattedMessage id="payment.failed" />
                            </span>
                          </li>
                        );
                        break;
                      case "Rejected":
                        status = (
                          <li>
                            <span className="status-failed">
                              <FormattedMessage id="payment.rejected" />
                            </span>
                          </li>
                        );
                        break;
                      case "PendingChallenge":
                        status = (
                          <li>
                            <span className="status-pending">
                              <FormattedMessage id="payment.PendingChallenge" />
                            </span>
                          </li>
                        );
                        break;
                      case "Blocked":
                        status = (
                          <li>
                            <span className="status-failed">
                              <FormattedMessage id="payment.blocked" />
                            </span>
                          </li>
                        );
                        break;
                      case "Expiration":
                        status = (
                          <li>
                            <span className="status-failed">
                              <FormattedMessage id="payment.expiration" />
                            </span>
                          </li>
                        );
                        break;
                      case "Disputed":
                        status = (
                          <li>
                            <span className="status-pending">
                              <FormattedMessage id="payment.disputed" />
                            </span>
                          </li>
                        );
                        break;
                      case "New":
                        status = (
                          <li>
                            <span className="status-pending">
                              <FormattedMessage id="payment.new" />
                            </span>
                          </li>
                        );
                        break;
                      case "ChargedBack":
                        status = (
                          <li>
                            <span className="status-failed">
                              <FormattedMessage id="payment.charged.back" />
                            </span>
                          </li>
                        );
                        break;
                      case "DisputeSucceeded":
                        status = (
                          <li>
                            <span className="status-succeeded">
                              <FormattedMessage id="payment.confirmed" />
                            </span>
                          </li>
                        );
                        break;
                      case "PartiallySucceded":
                        status = (
                          <li>
                            <span className="status-deactivated">
                              <FormattedMessage id="payment.partially.confirmed" />
                            </span>
                          </li>
                        );
                        break;
                      case "PartiallyCanceled":
                        status = (
                          <li>
                            <span className="status-deactivated">
                              <FormattedMessage id="payment.partially.canceled" />
                            </span>
                          </li>
                        );
                        break;
                      default:
                        status = <li></li>;
                        break;
                    }

                    return (
                      <Link
                        to={`/transactions/${order.number}/${order.storeId}/details`}
                        key={key}
                      >
                        <ul>
                          {status}
                          <li>
                            {intl.formatNumber(
                              order.isMultiplePayments
                                ? order.payment.amount * 2
                                : order.fxPayment !== undefined ? order.fxPayment.foreignGrossAmount : order.payment.amount,
                              {
                                style: "currency",
                                currency: isForeigner ? "BRL" : order.currency,
                                minimumFractionDigits: 2,
                              }
                            )}
                          </li>
                          {/* {isForeigner && order.fxPayment !== undefined && (
                            <li>
                              <FormattedNumber
                                value={
                                  order.fxPayment.nationalGrossAmount
                                }
                                style="currency"
                                currency="BRL"
                              />
                            </li>
                          )} */}
                          {/* {isForeigner && order.fxPayment === undefined && (
                            <li>
                              <FormattedNumber
                                value={
                                  order.isMultiplePayments
                                    ? order.foreignAmount * 2
                                    : order.foreignAmount
                                }
                                style="currency"
                                currency={order.currency}
                              />
                            </li>
                          )} */}
                          <li>{order.customerName}</li>
                          <li>{order.storeName}</li>
                          <li>{`${order!.dateTime
                            .split(",")[0]
                            .substr(0, 5)}, ${
                            order!.dateTime.split(",")[1]
                          }`}</li>
                        </ul>
                      </Link>
                    );
                  })
                ) : (
                  <>
                    {Array.from({ length: 5}, (_, index) => (
                      <div className="skeleton-container" key={index}>
                        <MyLoader />
                      </div>
                    ))}
                  </>
                )}
                
                
              </indexStyled.TableItems>
            </indexStyled.TableList>
          </ContentBox>
          <ContentBox
            refs={liveMapRef}
            cssClass="content-box-live-map list-box map border-top--mobile"
          >
            <indexStyled.Map>
              <LiveMap address={recentOrdersAddress} />
            </indexStyled.Map>
          </ContentBox>
        </styled.RowWith2GridColumns>

        <styled.RowWith3GridColumns
          className={`${
            accessLevel === 2 ? "border-top" : ""
          } column-in-mobile`}
        >
          {accessLevel !== undefined &&
            (accessLevel === 0 || accessLevel === 1) && (
              <ContentBox
                refs={balanceRef}
                cssClass="border-right flex-1 width-100-mobile border-bottom--mobile"
              >
                <styled.FinanceBottom>
                  <div className="title">
                    <FormattedMessage id="financial.title" />

                    <p
                      ref={balanceViewAllRef}
                      className="view-all__element"
                      onClick={(e: any) => history.push("/financials")}
                    >
                      {intl.formatMessage({ id: "notification.item.view.all" })}
                    </p>
                  </div>

                  <styled.AccountSelectContainer>
                    <div
                      className="selected"
                      onClick={() =>
                        setShowFinanceAccounts(!showFinanceAccounts)
                      }
                    >
                      <img
                        src={
                          selectedFinances === "brazil-finances"
                            ? brazilFinancesIcon
                            : globalFinancesIcon
                        }
                        alt="account"
                      />
                      <p>
                        {intl.formatMessage({
                          id:
                            selectedFinances === "brazil-finances"
                              ? "financial.withdrawal.national"
                              : "financial.withdrawal.international",
                        })}
                      </p>
                      <img src={selectArrowFinancesIcon} alt="arrow down" />
                    </div>

                    {showFinanceAccounts && (
                      <div className="select" ref={financeAccountsRef}>
                        <label
                          onClick={() =>
                            handleShowFinancesAccounts("brazil-finances")
                          }
                        >
                          <input
                            type="radio"
                            name="finances"
                            value="brazil-finances"
                          />
                          <span>
                            <img src={brazilFinancesIcon} alt="Brazil flag" />
                            {intl.formatMessage({
                              id: "financial.withdrawal.national",
                            })}
                          </span>
                        </label>

                        <label
                          onClick={() =>
                            handleShowFinancesAccounts("global-finances")
                          }
                        >
                          <input
                            type="radio"
                            name="finances"
                            value="global-finances"
                          />
                          <span>
                            <img src={globalFinancesIcon} alt="Global flag" />
                            {intl.formatMessage({
                              id: "financial.withdrawal.international",
                            })}
                          </span>
                        </label>
                      </div>
                    )}
                  </styled.AccountSelectContainer>

                  <styled.FinanceBox>
                    <SmallInfoLabel
                      tipText={intl.formatMessage({
                        id: "tip.home.finances.available.amount",
                      })}
                      isForeign={isForeigner}
                      label={intl.formatMessage({
                        id: "financial.amount.available.settlement",
                      })}
                      value={
                        balances.loaded ? (
                          intl.formatNumber(
                            !isForeigner
                              ? balances.nationalCurrentBalance
                              : balances.currentBalance,
                            {
                              style: "currency",
                              currency: !isForeigner ? "BRL" : currencyStore,
                            }
                          )
                        ) : (
                          <div className="skeleton-container">
                            <MyLoader />
                          </div>
                        )
                      }
                      brlAmount={
                        balances.loaded ? (
                          intl.formatNumber(balances.nationalCurrentBalance, {
                            style: "currency",
                            currency: "BRL",
                          })
                        ) : (
                          <div className="skeleton-container">
                            <MyLoader />
                          </div>
                        )
                      }
                    />

                    <SmallInfoLabel
                      tipText={intl.formatMessage({
                        id: "tip.home.finances.incoming.amount",
                      })}
                      isForeign={isForeigner}
                      label={intl.formatMessage({
                        id: "financial.amount.to.receive",
                      })}
                      value={
                        balances.loaded ? (
                          intl.formatNumber(
                            !isForeigner
                              ? balances.nationalAccountBalance
                              : balances.accountBalance,
                            {
                              style: "currency",
                              currency: !isForeigner ? "BRL" : currencyStore,
                            }
                          )
                        ) : (
                          <div className="skeleton-container">
                            <MyLoader />
                          </div>
                        )
                      }
                      brlAmount={
                        balances.loaded ? (
                          intl.formatNumber(balances.nationalAccountBalance, {
                            style: "currency",
                            currency: "BRL",
                          })
                        ) : (
                          <div className="skeleton-container">
                            <MyLoader />
                          </div>
                        )
                      }
                    />
                  </styled.FinanceBox>
                </styled.FinanceBottom>
              </ContentBox>
            )}

          <ContentBox cssClass="padding-vertical-unset width-100-mobile faq-column-in-mobile border-bottom--mobile">
            <styled.Faq>
              <div className="title">
                <FormattedMessage id="home.faq" />
              </div>
              <FaqItem
                question={intl.formatMessage({ id: "home.faq.question.1" })}
                answer={intl.formatMessage({ id: "home.faq.answer.1" })}
              />
              <FaqItem
                question={intl.formatMessage({ id: "home.faq.question.2" })}
                answer={intl.formatMessage({ id: "home.faq.answer.2" })}
              />
              <FaqItem
                question={intl.formatMessage({ id: "home.faq.question.3" })}
                answer={intl.formatMessage({ id: "home.faq.answer.3" })}
              />
              <FaqItem
                question={intl.formatMessage({ id: "home.faq.question.4" })}
                answer={intl.formatMessage({ id: "home.faq.answer.4" })}
              />
              <FaqItem
                question={intl.formatMessage({ id: "home.faq.question.5" })}
                answer={intl.formatMessage({ id: "home.faq.answer.5" })}
              />
              <FaqItem
                question={intl.formatMessage({ id: "home.faq.question.6" })}
                answer={intl.formatMessage({ id: "home.faq.answer.6" })}
              />
            </styled.Faq>
          </ContentBox>

          <ContentBox
            refs={helpRef}
            cssClass="border-left padding-vertical-unset width-100-mobile"
          >
            <styled.Help>
              <div className="title">
                <FormattedMessage id="home.help" />
              </div>

              <div>
                <p>{intl.formatMessage({ id: "home.help.text" })}</p>
              </div>

              <styled.ListWithIcons>
                <a
                  href="mailto:help@primefy.io?subject=[URGENT] Merchant Support"
                  className="item"
                >
                  <styled.Item>
                    <styled.ItemIcon icon={chatIcon}></styled.ItemIcon>

                    <div className="label">
                      <p>Email</p>
                      <span>
                        <FormattedMessage id="home.email.wait.time" />
                      </span>
                    </div>
                  </styled.Item>
                </a>
                <a
                  href="javascript: $zopim.livechat.window.show();"
                  className="item"
                >
                  <styled.Item>
                    <styled.ItemIcon icon={emailIcon}></styled.ItemIcon>

                    <div className="label">
                      <p>Chat</p>
                      <span>
                        <FormattedMessage id="home.chat.wait.time" />
                      </span>
                    </div>
                  </styled.Item>
                </a>
              </styled.ListWithIcons>
            </styled.Help>
          </ContentBox>
        </styled.RowWith3GridColumns>
      </LayoutWrapper>
    </>
  );
}

export default memo(Home);
