import { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

//Services
import OrderService from "services/Order";
import SessionService from "services/Session";
import StoreService from "services/Store";

//Data
import bexsAllowCurrency from "helpers/bexsAllowCurrency";

//Types
import { Balance, CustomerAddress, RecentOrder } from "../types";

export const useHome = () => {
  const transferPlataformTypeArray = ["", "SWIFT", "BIC", "IBAN"];
  const [isForeigner, setIsForeigner] = useState(false);
  const [currencyStore, setCurrencyStore] = useState("BRL");
  const [handleWhy, setHandleWhy] = useState(false);
  const [selectedFinances, setSelectedFinances] = useState("brazil-finances");
  const [showFinanceAccounts, setShowFinanceAccounts] = useState(false);
  const [showTour, setShowTour] = useState(true);
  const [allReceivables, setAllReceivables] = useState<any>([]);

  const financeAccountsRef = useRef<HTMLDivElement>(null);
  const homeRef = useRef<HTMLDivElement>(null);
  const overviewRef = useRef<HTMLDivElement>(null);
  const last5OrdersRef = useRef<HTMLDivElement>(null);
  const liveMapRef = useRef<HTMLDivElement>(null);
  const balanceRef = useRef<HTMLDivElement>(null);
  const balanceViewAllRef = useRef<HTMLDivElement>(null);
  const helpRef = useRef<HTMLDivElement>(null);

  const [balances, setBalances] = useState<Balance>({
    currentBalance: 0,
    nationalCurrentBalance: 0,
    currentBlockedBalance: 0,
    nationalCurrentBlockedBalance: 0,
    accountBalance: 0,
    nationalAccountBalance: 0,
    loaded: false,
  });

  const [isRecentOrdersLoaded, setIsRecentOrdersLoaded] =
    useState<boolean>(false);
  const [recentOrders, setRecentOrders] = useState<RecentOrder[]>([]);
  const [recentOrdersAddress, setRecentOrdersAddress] = useState<
    CustomerAddress[]
  >([]);

  const [accessLevel, setAccessLevel] = useState<number | undefined>(undefined);

  let history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  const setAllOrders = useCallback(() => {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
      
    switch (session.role) {
      case "Business.Owner":
      case "Store.Owner":
      case "Administrator.Owner":
        setAccessLevel(0);
        break;
      case "Business.Administrator":
      case "Store.Administrator":
        setAccessLevel(1);
        break;
      case "Business.Agent":
      case "Store.Agent":
        setAccessLevel(2);
        break;
      case "Business.ViewOnly":
      case "Store.ViewOnly":
        setAccessLevel(3);
        break;
      case "Business.API":
      case "Store.API":
        setAccessLevel(4);
        break;
      default:
        setAccessLevel(undefined);
    }

    OrderService.getDistinctRecentOrdersForHomePage({ pageSize: 5 })
      .then((response) => {
        let orders: RecentOrder[] = [];
        let ordersAddress: CustomerAddress[] = [];

        response.data.results.forEach((value: any) => {
          let paymentStatus = "";

          if (
            value.payment.type === "Boleto" &&
            value.payment.installments > 1
          ) {
            OrderService.getBoletoInstallments(
              value.number,
              value.storeId
            ).then((response) => {
              if (response.data[0].status === "Paid") {
                const old = allReceivables;
                old.push(response.data[0].orderNumber);
                setAllReceivables(old);
              }
            });
          }

          let timeZoneOffset = new Date(value.createdOn).getTimezoneOffset();
          let timeZoneOffsetPositive =
            new Date(value.createdOn).getTimezoneOffset() * -1;

          let formatDate =
            timeZoneOffset > 0
              ? new Date(
                  new Date(value.createdOn).getTime() -
                    timeZoneOffset * 60 * 1000
                )
              : new Date(
                  new Date(value.createdOn).getTime() +
                    timeZoneOffsetPositive * 60 * 1000
                );
          let dateFormattedTimeZone = moment(formatDate).format();

          orders.push({
            id: value.id,
            status: value.status,
            number: value.number,
            customerName: `${value.customer.firstName} ${
              value.customer.surname ? value.customer.surname : ""
            }`,
            isMultiplePayments: value.isMultiplePayments,
            paymentType: value.payment.type,
            payment: {
              status: value.payment.status,
              amount:
                value.payment.installments > 1 &&
                value.payment.type === "Boleto"
                  ? value.payment.amount * value.payment.installments
                  : value.payment.amount,
              installments: value.payment.installments,
            },
            fxPayment: value.fxPayment ? {
              ...value.fxPayment,
              foreignGrossAmount: value.fxPayment.foreignGrossAmount,
              nationalGrossAmount: value.fxPayment.nationalGrossAmount
            } : undefined,
            foreignAmount: value.fxPayment
              ? (value.payment.installments > 1 &&
                value.payment.type === "Boleto"
                  ? value.amount * value.payment.installments
                  : value.amount) / value.fxPayment.exchangeRate
              : 0,
            storeId: value.storeId,
            subscription: value.subscription ? value.subscription : undefined,
            currency: value.currency ? value.currency : "BRL",
            storeName: value.storeName,
            dateTime: format(new Date(dateFormattedTimeZone), "P, HH:mm", {
              locale: navigator.language === "pt-BR" ? ptBR : undefined,
            }),
          });
        });

        response.data.results.forEach((value: any) => {
          ordersAddress.push({
            city: value.customer.address ? value.customer.address.city : "",
            district: value.customer.address
              ? value.customer.address.district
              : "",
            number: value.customer.address ? value.customer.address.number : "",
            street: value.customer.address ? value.customer.address.street : "",
            status: value.payment.status,
          });
        });

        setRecentOrders(orders);
        setRecentOrdersAddress(ordersAddress);
        setIsRecentOrdersLoaded(true);
        // connectionWebsocket();
      })
      .catch((err) => console.log(err));
  }, [recentOrders]);

  
  const countryCurrencyArray = ["BRL", "GBP", "USD"];
  const currencySymbol = ["R$", "£", "US$"];

  function setAllValues() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    StoreService.get().then((response: any) => {
      if (response.data.isForeigner) {
        StoreService.getBalancesWithForeignAmount()
          .then((response) => {
            setBalances({
              currentBalance: 0,
              nationalCurrentBalance: 0,
              currentBlockedBalance: 0,
              nationalCurrentBlockedBalance: 0,
              accountBalance: 0,
              nationalAccountBalance: 0,
              loaded: true,
            });
          })
          .catch((err) => console.log(err));
      } else {
        StoreService.getBalances(0)
          .then((response) => {
            setBalances({
              currentBalance: 0,
              nationalCurrentBalance: response.data.available,
              currentBlockedBalance: 0,
              nationalCurrentBlockedBalance: 0,
              accountBalance: 0,
              nationalAccountBalance: response.data.incoming,
              loaded: true,
            });
          })
          .catch((err) => console.log(err));
      }

      setIsForeigner(response.data.isForeigner);

      let currencyFromAPI = { id: 0, currency: "BRL", symbol: "R$" };

      if (response.data.isForeigner) {
        let findCurrency = bexsAllowCurrency.find((value) => {
          return value.currency === response.data.fxMerchant.account.currency;
        });

        if (findCurrency) currencyFromAPI = findCurrency;
      }

      setCurrencyStore(currencyFromAPI.currency);

      localStorage.setItem(
        "@PrimefyStore",
        JSON.stringify({
          isForeigner: response.data.isForeigner,
          currency: currencyFromAPI.currency,
          currencySymbol: currencyFromAPI.symbol,
        })
      );
    });
  }

  useEffect(() => {
    if (window.location.pathname === "/") {
      const buttonToShowChat = document.createElement("a");
      buttonToShowChat.href = "javascript: $zopim.livechat.button.show();";
      document.body.appendChild(buttonToShowChat);
      buttonToShowChat.click();

      setTimeout(() => {
        document.body.removeChild(buttonToShowChat);
      }, 1000);
    }

    return () => {
      const buttonToHideChat = document.createElement("a");
      buttonToHideChat.href = "javascript: $zopim.livechat.window.hide();";
      document.body.appendChild(buttonToHideChat);

      buttonToHideChat.click();

      setTimeout(() => {
        document.body.removeChild(buttonToHideChat);
      }, 1000);
    };
  }, [window.location.pathname]);

  useEffect(() => {
    setAllOrders();

    setAllValues();
  }, []);

  useOutsideAlerter(financeAccountsRef);

  function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>) {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as any)) {
          if (showFinanceAccounts) {
            setShowFinanceAccounts(false);
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, showFinanceAccounts]);
  }

  function handleShowFinancesAccounts(value: string) {
    setSelectedFinances(value);

    setShowFinanceAccounts(false);
  }

  return {
    showTour,
    setShowTour,
    homeRef,
    overviewRef,
    last5OrdersRef,
    liveMapRef,
    balanceRef,
    balanceViewAllRef,
    helpRef,
    accessLevel,
    recentOrders,
    currencyStore,
    isForeigner,
    isRecentOrdersLoaded,
    recentOrdersAddress,
    setShowFinanceAccounts,
    showFinanceAccounts,
    selectedFinances,
    handleShowFinancesAccounts,
    financeAccountsRef,
    balances,
  };
};
