import React, { useState, useEffect } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

//Components
import MyLoader from "components/ContentLoader";
import {
  ContentBox,
  LayoutWrapper,
  Pagination,
} from "components/Layout/Index";
import Search from "components/Layout/Search";
import DownloadLoading from "components/DownloadLoading";

import FilterExportButtonModal from "components/Layout/Modals/FilterExportButtonModal";
import Input from "components/Forms/InputFloatLabel";

//Services
import OrderService from "services/Order";
import SessionService from "services/Session";
import { useSettings } from "../store/settings";

//Assets
import searchIcon from "assets/images/global/search-transactions.svg";

//Styles
import * as indexStyled from "../styles/index-styles";
import * as styled from "./Home/styles";

interface IOrder {
  id: number;
  status: string;
  number: string;
  customerName: string;
  paymentType: string;
  amount: number;
  // payment: IPayment;
  storeId: number;
  // subscription: any | undefined;
  // foreignAmount: number;
  // isMultiplePayments?: boolean;
  // isMultiplePaymentsOrderStatus?: string;
  currency?: string;
  storeName?: string;
  dateTime: string;
  products: string;
}

interface IPayment {
  status: string;
  amount: number;
  installments: number;
}

function Transactions() {
  const [isOrdersLoaded, setIsOrdersLoaded] = useState<boolean>(false);
  const [Orders, setOrders] = useState<IOrder[]>([]);
  const [allOrders, setAllOrders] = useState<IOrder[]>([]);
  const [numberTotalOfOrders, setNumberTotalOfOrders] = useState(1);
  const [isSearch, setIsSearch] = useState(false);
  const [startShowOrders, setStartShowOrders] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageIndexProcessing, setPageIndexProcessing] = useState(false);
  const [isWaitingDownload, setIsWaitingDownload] = useState(false);
  const [showNotFound, setShowNotFound] = useState(false);
  const [percentageDownload, setPercentageDownload] = useState(0);
  const [filterQuery, setFilterQuery] = useState("");
  const [rangeDateFilter, setRangeDateFilter] = useState(["", ""]);
  const [allReceivables, setAllReceivables] = useState<any>([]);

  const [periodTypeState, setPeriodTypeState] = useState("");

  const { setSettings, settings } = useSettings((state) => state);



  const [searchedOrder, setSearchedOrder] = useState({
    type: "number",
    value: "",
  });

  const [isMultiplePaymentsOrderStatus, setIsMultiplePaymentsOrderStatus] =
    useState("");

  let history = useHistory();
  let { state } = useLocation();
  let location = useLocation();
  const intl = useIntl();

  if (!state) {
    state = "";
  }

  if (!SessionService.isValid()) {
    SessionService.clean();

    // if(/test/.test(window.location.href)) {
    //   window.location.href = `https://dashboard.premepay.com/login`;
    // }else {
    //   history.push("/login");
    // }
    history.push("/login");
  }

  function setAllOrdersEffect() {
    setPageIndexProcessing(true);

    OrderService.getDistinctRecentOrders({ pageSize: 50, pageIndex: pageIndex })
    .then((response) => {
      let orders: IOrder[] = [];
      let isMultiplePaymentsOrderStatusOrder = "";
      
        response.data.results.forEach((value: any) => {
          if (value.isMultiplePayments) {
            if (
              value.payments[0] === "Disputed" ||
              value.payments[1] === "Disputed"
            ) {
              isMultiplePaymentsOrderStatusOrder = "Disputed";
            } else if (
              value.payments[0] === "ChargedBack" ||
              value.payments[1] === "ChargedBack"
            ) {
              isMultiplePaymentsOrderStatusOrder = "ChargedBack";
            } else if (
              (value.payments[0] === "ChargedBack" ||
                value.payments[0] === "Disputed") &&
              (value.payments[1] === "ChargedBack" ||
                value.payments[1] === "Disputed")
            ) {
              isMultiplePaymentsOrderStatusOrder = "Disputed";
            } else {
              isMultiplePaymentsOrderStatusOrder = value.payments[0].status;
            }
          }

          let timeZoneOffset = new Date(value.createdOn).getTimezoneOffset();
          let timeZoneOffsetPositive =
            new Date(value.createdOn).getTimezoneOffset() * -1;

          let formatDate =
            timeZoneOffset > 0
              ? new Date(
                  new Date(value.createdOn).getTime() -
                    timeZoneOffset * 60 * 1000
                )
              : new Date(
                  new Date(value.createdOn).getTime() +
                    timeZoneOffsetPositive * 60 * 1000
                );
          let dateFormattedTimeZone = moment(formatDate).format();

          let orderProductsName = "";
          if (value.products && value.products.length > 0) {
            const reducedProducts = value.products.reduce(
              (acc: any, product: any) => {
                return [...acc, product.name];
              },
              []
            );

            orderProductsName =
              reducedProducts.length > 0
                ? reducedProducts.join(", ")
                : reducedProducts[0];
          }

          orders.push({
            id: value.orderId,
            status: value.paymentStatus,
            number: value.orderNumber,
            customerName: value.customerName,
            amount: value.orderAmount,
            // isMultiplePayments: value.isMultiplePayments,
            // isMultiplePaymentsOrderStatus: isMultiplePaymentsOrderStatusOrder,
            paymentType: value.paymentType,
            // payment: {
            //   status: value.payment.status,
            //   amount:
            //     value.payment.installments > 1 &&
            //     value.payment.type === "Boleto"
            //       ? value.payment.amount
            //       : value.payment.amount,
            //   installments: value.payment.installments,
            // },
            // foreignAmount: value.fxPayment
            //   ? (value.payment.installments > 1 &&
            //     value.payment.type === "Boleto"
            //       ? value.amount
            //       : value.amount) / value.fxPayment.exchangeRate
            //   : 0,
            storeId: value.storeId,
            // subscription: value.subscription ? value.subscription : undefined,
            currency: value.currency ? value.currency : "BRL",
            storeName: value.storeName,
            dateTime: format(new Date(dateFormattedTimeZone), "P, HH:mm", {
              locale: navigator.language === "pt-BR" ? ptBR : undefined,
            }),
            products: orderProductsName,
          });
        });

        setOrders(orders);
        setAllOrders(orders);
        setIsOrdersLoaded(true);
        setPageIndexProcessing(false);
        setNumberTotalOfOrders(response.data.query_count);
      })
      .catch((err) => {
        console.log(err);
        setPageIndexProcessing(false);
      });
  }

  const premepayStore = localStorage.getItem("@PrimefyStore");
  const { isForeigner, currency, currencySymbol } = JSON.parse(
    premepayStore ? premepayStore : "{}"
  );

  useEffect(() => {
    if (location.pathname === "/search") {
      setIsSearch(true);
      setStartShowOrders(false);
    } else {
      setIsSearch(false);
      setStartShowOrders(true);
    }

    filterQuery !== "" ? getFilteredOrders(filterQuery) : setAllOrdersEffect();
  }, [state, pageIndex]);

  useEffect(() => {
    if (searchedOrder.value.length === 0) {
      setAllOrdersEffect();
    }
  }, [searchedOrder]);

  function searchOrderNumber() {
    OrderService.getResearchedTransaction({
      type: searchedOrder.type,
      value: searchedOrder.value,
    })
      .then((response) => {
        setStartShowOrders(true);
        let orders: IOrder[] = [];

        let isMultiplePaymentsOrderStatusOrder = "";

        response.data.forEach((value: any) => {
          if (value.isMultiplePayments) {
            if (
              value.payments[0] === "Disputed" ||
              value.payments[1] === "Disputed"
            ) {
              isMultiplePaymentsOrderStatusOrder = "Disputed";
            } else if (
              value.payments[0] === "ChargedBack" ||
              value.payments[1] === "ChargedBack"
            ) {
              isMultiplePaymentsOrderStatusOrder = "ChargedBack";
            } else if (
              (value.payments[0] === "ChargedBack" ||
                value.payments[0] === "Disputed") &&
              (value.payments[1] === "ChargedBack" ||
                value.payments[1] === "Disputed")
            ) {
              isMultiplePaymentsOrderStatusOrder = "Disputed";
            } else {
              isMultiplePaymentsOrderStatusOrder = value.payments[0].status;
            }
          }

          let timeZoneOffset = new Date(value.createdOn).getTimezoneOffset();
          let timeZoneOffsetPositive =
            new Date(value.createdOn).getTimezoneOffset() * -1;

          let formatDate =
            timeZoneOffset > 0
              ? new Date(
                  new Date(value.createdOn).getTime() -
                    timeZoneOffset * 60 * 1000
                )
              : new Date(
                  new Date(value.createdOn).getTime() +
                    timeZoneOffsetPositive * 60 * 1000
                );
          let dateFormattedTimeZone = moment(formatDate).format();

          let orderProductsName = "";
          if (value.products && value.products.length > 0) {
            const reducedProducts = value.products.reduce(
              (acc: any, product: any) => {
                return [...acc, product.name];
              },
              []
            );

            orderProductsName =
              reducedProducts.length > 0
                ? reducedProducts.join(", ")
                : reducedProducts[0];
          }

          orders.push({
            id: value.id,
            status: value.payment.status,
            number: value.number,
            customerName: `${value.customer.firstName} ${
              value.customer.surname ? value.customer.surname : ""
            }`,
            amount: value.payment.amount,
            // isMultiplePayments: value.isMultiplePayments,
            // isMultiplePaymentsOrderStatus: isMultiplePaymentsOrderStatusOrder,
            paymentType: value.payment.type,
            // payment: {
            //   status: value.payment.status,
            //   amount:
            //     value.payment.installments > 1 &&
            //     value.payment.type === "Boleto"
            //       ? value.payment.amount
            //       : value.payment.amount,
            //   installments: value.payment.installments,
            // },
            // foreignAmount: value.fxPayment
            //   ? (value.payment.installments > 1 &&
            //     value.payment.type === "Boleto"
            //       ? value.amount
            //       : value.amount) / value.fxPayment.exchangeRate
            //   : 0,
            storeId: value.storeId,
            // subscription: value.subscription ? value.subscription : undefined,
            currency: value.currency ? value.currency : "BRL",
            storeName: value.storeName,
            dateTime: format(new Date(dateFormattedTimeZone), "P, HH:mm", {
              locale: navigator.language === "pt-BR" ? ptBR : undefined,
            }),
            products: orderProductsName,
          });
        });

        setOrders(orders);
        setIsOrdersLoaded(true);
        setPageIndexProcessing(false);
        setShowNotFound(false);
      })
      .catch((err) => {
        console.log(err);
        setStartShowOrders(true);
        setPageIndexProcessing(false);
        setShowNotFound(true);
      });
  }

  function getFilteredOrders(filterQuery: string) {
    setPageIndexProcessing(true);
    setIsOrdersLoaded(false);

    OrderService.getFilteredOrders({
      pageSize: 50,
      pageIndex: pageIndex,
      query: filterQuery,
    })
      .then((response) => {
        let orders: IOrder[] = [];
        let isMultiplePaymentsOrderStatusOrder = "";
        let oldReceivables: any = [];

        response.data.results.forEach((value: any) => {
          if (value.isMultiplePayments) {
            if (
              value.payments[0] === "Disputed" ||
              value.payments[1] === "Disputed"
            ) {
              isMultiplePaymentsOrderStatusOrder = "Disputed";
            } else if (
              value.payments[0] === "ChargedBack" ||
              value.payments[1] === "ChargedBack"
            ) {
              isMultiplePaymentsOrderStatusOrder = "ChargedBack";
            } else if (
              (value.payments[0] === "ChargedBack" ||
                value.payments[0] === "Disputed") &&
              (value.payments[1] === "ChargedBack" ||
                value.payments[1] === "Disputed")
            ) {
              isMultiplePaymentsOrderStatusOrder = "Disputed";
            } else {
              isMultiplePaymentsOrderStatusOrder = value.payments[0].status;
            }
          }

          // if (
          //   value.payment.type === "Boleto" &&
          //   value.payment.installments > 1
          // ) {
          //   OrderService.getBoletoInstallments(
          //     value.number,
          //     value.storeId
          //   ).then((response) => {
          //     if (response.data[0].status === "Paid") {
          //       const old = allReceivables;
          //       old.push(response.data[0].orderNumber);
          //       setAllReceivables(old);
          //     }
          //   });
          // }

          let timeZoneOffset = new Date(value.createdOn).getTimezoneOffset();
          let timeZoneOffsetPositive =
            new Date(value.createdOn).getTimezoneOffset() * -1;

          let formatDate =
            timeZoneOffset > 0
              ? new Date(
                  new Date(value.createdOn).getTime() -
                    timeZoneOffset * 60 * 1000
                )
              : new Date(
                  new Date(value.createdOn).getTime() +
                    timeZoneOffsetPositive * 60 * 1000
                );
          let dateFormattedTimeZone = moment(formatDate).format();

          let orderProductsName = "";
          if (value.products && value.products.length > 0) {
            const reducedProducts = value.products.reduce(
              (acc: any, product: any) => {
                return [...acc, product.name];
              },
              []
            );

            orderProductsName =
              reducedProducts.length > 0
                ? reducedProducts.join(", ")
                : reducedProducts[0];
          }

          orders.push({
            id: value.orderId,
            status: value.paymentStatus,
            number: value.orderNumber,
            customerName: value.customerName,
            amount: value.orderAmount,
            // isMultiplePayments: value.isMultiplePayments,
            // isMultiplePaymentsOrderStatus: isMultiplePaymentsOrderStatusOrder,
            paymentType: value.paymentType,
            // payment: {
            //   status: value.payment.status,
            //   amount:
            //     value.payment.installments > 1 &&
            //     value.payment.type === "Boleto"
            //       ? value.payment.amount
            //       : value.payment.amount,
            //   installments: value.payment.installments,
            // },
            // foreignAmount: value.fxPayment
            //   ? (value.payment.installments > 1 &&
            //     value.payment.type === "Boleto"
            //       ? value.amount
            //       : value.amount) / value.fxPayment.exchangeRate
            //   : 0,
            storeId: value.storeId,
            // subscription: value.subscription ? value.subscription : undefined,
            currency: value.currency ? value.currency : "BRL",
            storeName: value.storeName,
            dateTime: format(new Date(dateFormattedTimeZone), "P, HH:mm", {
              locale: navigator.language === "pt-BR" ? ptBR : undefined,
            }),
            products: orderProductsName,
          });
        });

        setOrders(orders);
        setAllOrders(orders);
        setNumberTotalOfOrders(response.data.query_count);
        setIsOrdersLoaded(true);
        setPageIndexProcessing(false);
      })
      .catch((err) => {
        console.log(err);
        setIsOrdersLoaded(true);
        setPageIndexProcessing(false);
      });
  }

  function handleSelectFilter(
    statusFilter: string,
    periodFilter: string,
    methodFilter: string,
    typeFilter: string,
    periodType: string
  ) {
    setPeriodTypeState(periodType);

    let filterByStatus = !["all", ""].includes(statusFilter);
    let filterByMethod = !["all", ""].includes(methodFilter);
    let filterByType = ["single", "recurring"].includes(typeFilter);

    let startDate = moment(rangeDateFilter[0], "DD/MM/YYYY");
    let endDate = moment(rangeDateFilter[1], "DD/MM/YYYY");
    let startDateFormatted = moment(startDate).format("YYYY-MM-DD");
    let endDateFormatted = moment(endDate).format("YYYY-MM-DD");

    let startDateQuery = moment(rangeDateFilter[0]).isValid()
      ? `&startDate=${startDateFormatted}`
      : "";
    let endDateQuery = moment(rangeDateFilter[1]).isValid()
      ? `&endDate=${endDateFormatted}`
      : "";
    let methodQuery = filterByMethod ? `&PaymentMethod=${methodFilter}` : "";
    let statusQuery =
      filterByStatus || periodType === "payment"
        ? `&Status=${periodType === "payment" ? "Succeded" : statusFilter}`
        : "";
    let typeQuery = filterByType ? (typeFilter === 'single' ? `&Type=Single` : `&Type=Recurrent`) : "";
    let periodTypeQuery =
      periodType !== "creation" ? `&usePaymentDate=true` : "";
    
    setFilterQuery(
      `${startDateQuery}${endDateQuery}${methodQuery}${statusQuery}${typeQuery}${periodTypeQuery}`
    );
    let finalQuery = `${startDateQuery}${endDateQuery}${methodQuery}${statusQuery}${typeQuery}${periodTypeQuery}`;

    getFilteredOrders(finalQuery);
  }

  function exportTransactions() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    const filterStorageString = localStorage.getItem("filter");

    const { status, method, type } = JSON.parse(
      filterStorageString ? filterStorageString : "{}"
    );

    let startDate = moment(rangeDateFilter[0], "DD/MM/YYYY");
    let endDate = moment(rangeDateFilter[1], "DD/MM/YYYY");
    let startDateFormatted = moment(startDate).format("YYYY-MM-DD");
    let endDateFormatted = moment(endDate).format("YYYY-MM-DD");

    let filterByStatus = !["all", ""].includes(status);
    let filterByMethod = !["all", ""].includes(method);
    let filterByType = ["Single", "Recurrent"].includes(type);

    let startDateQuery = moment(rangeDateFilter[0]).isValid()
      ? `&startDate=${startDateFormatted}`
      : "";
    let endDateQuery = moment(rangeDateFilter[1]).isValid()
      ? `&endDate=${endDateFormatted}`
      : "";
    let methodQuery = filterByMethod ? `&PaymentMethod=${method}` : "";
    let statusQuery = filterByStatus ? `&Status=${status}` : "";
    let typeQuery = filterByType ? `&Type=Recurrent` : "";
    let periodTypeQuery =
      periodTypeState !== "creation" ? `&usePaymentDate=true` : "";

    let entityTypeAndId =
      session.entityType === "Business"
        ? `?businessid=${session.businessId}`
        : `?storeid=${session.storeId}`;

    let finalQuery = `${entityTypeAndId}${startDateQuery}${endDateQuery}${methodQuery}${statusQuery}${typeQuery}${periodTypeQuery}`;

    setIsWaitingDownload(true);

    //Create XMLHTTP Request.
    var req = new XMLHttpRequest();
    req.open(
      "GET",
      `${process.env.REACT_APP_API_BASE_URL}/stores/orders/export${finalQuery}`,
      true
    );
    req.setRequestHeader("Authorization", `Bearer ${session.token}`);
    req.responseType = "blob";
    req.onload = function () {
      //Convert the Byte Data to BLOB object.
      var blob = new Blob([req.response], { type: "application/octetstream" });

      //Check the Browser type and download the File.
      var url = window.URL || window.webkitURL;
      let link = url.createObjectURL(blob);
      var a = document.createElement("a");
      a.setAttribute("download", "orders-premepay.xlsx");
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    req.onprogress = function (event) {
      setPercentageDownload(
        parseFloat(((event.loaded / event.total) * 100).toFixed(0))
      );
    };

    req.send();
  }

  useEffect(() => {
    if (percentageDownload === 100) {
      setTimeout(() => {
        setIsWaitingDownload(false);

        setPercentageDownload(0);
      }, 1000);
    }
  }, [percentageDownload]);

  useEffect(() => {
    setIsOrdersLoaded(true);
    setPageIndexProcessing(false);
    setShowNotFound(false);
    setOrders(allOrders);
  }, [searchedOrder.type]);

  function clearFilter() {
    setOrders(allOrders);
  }

  function handleSearchedOrder(name: string, value: string) {
    setSearchedOrder((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  return (
    <>
      <LayoutWrapper>
        {isSearch && (
          <Search
            placeholder={intl.formatMessage({ id: "home.search.order" })}
          />
        )}

        <DownloadLoading
          isLoading={isWaitingDownload}
          percentage={percentageDownload}
        />

        {startShowOrders && (
          <indexStyled.RowListWithMarginBottom>
            <ContentBox cssClass="m-top-25 list-transactions">
              <indexStyled.TableList>
                <indexStyled.ContextBoxHeader>
                  <div className="title">
                    <FormattedMessage id="transactions.title" />
                  </div>
                  <div className="selects">
                    <Input
                      mode="expand-search"
                      placeholder={`${intl.formatMessage({
                        id: `transactions.search`,
                      })} ${intl.formatMessage({
                        id: `transactions.search.${searchedOrder.type}`,
                      })}`}
                      value={searchedOrder.value}
                      handler={handleSearchedOrder}
                      icon={searchIcon}
                      type="text"
                      onKeyDown={(e) =>
                        e.keyCode === 13 ? searchOrderNumber() : null
                      }
                      searchFunction={() => searchOrderNumber()}
                    />

                    <FilterExportButtonModal
                      action={(
                        statusFilter: string,
                        periodFilter: string,
                        methodFilter: string,
                        typeFilter: string,
                        periodType: string
                      ) => {
                        handleSelectFilter(
                          statusFilter,
                          periodFilter,
                          methodFilter,
                          typeFilter,
                          periodType
                        );
                      }}
                      clear={() => {
                        clearFilter();
                      }}
                      rangeDateFilter={rangeDateFilter}
                      setRangeDateFilter={setRangeDateFilter}
                      rightHandler={exportTransactions}
                    />
                  </div>
                </indexStyled.ContextBoxHeader>
                <indexStyled.TableListHeader>
                  <ul>
                    <li>
                      <FormattedMessage id="home.status" />
                    </li>
                    <li>
                      <FormattedMessage id="detail.order.number" />
                    </li>
                    <li>
                      <FormattedMessage id="home.brl.amount" />
                    </li>
                    {settings.isForeigner && (
                      <li>
                        {"R$"}{" "}
                        <FormattedMessage id="home.new.currency.amount" />
                      </li>
                    )}
                    <li>
                      <FormattedMessage id="home.name" />
                    </li>
                    <li>
                      <FormattedMessage id="home.method" />
                    </li>
                    <li>
                      <FormattedMessage id="view.all.product" />
                    </li>
                    <li>
                      <FormattedMessage id="admin.order.list.store.name" />
                    </li>

                    <li>
                      <FormattedMessage id="home.date" />
                    </li>
                  </ul>
                </indexStyled.TableListHeader>
                <indexStyled.TransactionsTable>
                  <span className="transactions-list-table">
                    {showNotFound ? (
                      <h3>{intl.formatMessage({ id: "items.not.found" })}</h3>
                    ) : null}

                    {isOrdersLoaded && Orders.length > 0 && !showNotFound
                      ? Orders.map((order: IOrder, key: number) => {
                          let status;
                          let paymentType;

                          let paymentStatus = order.status;

                          if ([order.number].includes(allReceivables)) {
                            paymentStatus = "Succeded";
                          }

                          // if (order.number === 'P636628') {
                          // }

                          switch (
                            // order.isMultiplePayments
                              // ? order.isMultiplePaymentsOrderStatus
                              paymentStatus
                          ) {
                            case "Pending":
                              status = (
                                <li>
                                  <span className="status-pending">
                                    <FormattedMessage id="payment.pending" />
                                  </span>
                                </li>
                              );
                              break;
                            case "PreAuthorized":
                              status = (
                                <li>
                                  <span className="status-pending">
                                    <FormattedMessage id="status.pre.authorized" />
                                  </span>
                                </li>
                              );
                              break;
                            case "Succeded":
                              status = (
                                <li>
                                  <span className="status-succeeded">
                                    <FormattedMessage id="payment.confirmed" />
                                  </span>
                                </li>
                              );
                              break;
                            case "Canceled":
                            case "Expired":
                              status = (
                                <li>
                                  <span className="status-failed">
                                    <FormattedMessage id="payment.canceled" />
                                  </span>
                                </li>
                              );
                              break;
                            case "Rejected":
                              status = (
                                <li>
                                  <span className="status-failed">
                                    <FormattedMessage id="payment.rejected" />
                                  </span>
                                </li>
                              );
                              break;
                            case "PendingChallenge":
                            status = (
                              <li>
                                <span className="status-pending">
                                  <FormattedMessage id="payment.PendingChallenge" />
                                </span>
                              </li>
                            );
                            break;
                            case "Failed":
                              status = (
                                <li>
                                  <span className="status-failed">
                                    <FormattedMessage id="payment.failed" />
                                  </span>
                                </li>
                              );
                              break;
                            case "New":
                              status = (
                                <li>
                                  <span className="status-pending">
                                    <FormattedMessage id="payment.new" />
                                  </span>
                                </li>
                              );
                              break;
                            case "Blocked":
                              status = (
                                <li>
                                  <span className="status-failed">
                                    <FormattedMessage id="payment.blocked" />
                                  </span>
                                </li>
                              );
                              break;
                            case "Disputed":
                              status = (
                                <li>
                                  <span className="status-pending">
                                    <FormattedMessage id="payment.disputed" />
                                  </span>
                                </li>
                              );
                              break;
                            case "ChargedBack":
                              status = (
                                <li>
                                  <span className="status-failed">
                                    <FormattedMessage id="payment.charged.back" />
                                  </span>
                                </li>
                              );
                              break;
                              case "Expiration":
                              status = (
                                <li>
                                  <span className="status-failed">
                                    <FormattedMessage id="payment.charged.back" />
                                  </span>
                                </li>
                              );
                              break;
                            case "DisputeSucceeded":
                              status = (
                                <li>
                                  <span className="status-succeeded">
                                    <FormattedMessage id="payment.confirmed" />
                                  </span>
                                </li>
                              );
                              break;
                            case "PartiallySucceded":
                              status = (
                                <li>
                                  <span className="status-deactivated">
                                    <FormattedMessage id="payment.partially.confirmed" />
                                  </span>
                                </li>
                              );
                              break;
                            case "PartiallyCanceled":
                              status = (
                                <li>
                                  <span className="status-deactivated">
                                    <FormattedMessage id="payment.partially.canceled" />
                                  </span>
                                </li>
                              );
                              break;
                            default:
                              status = <li></li>;
                              break;
                          }

                          switch (order.paymentType) {
                            case "Credit":
                              paymentType = (
                                <FormattedMessage id="payment.credit.card" />
                              );
                              break;
                            case "Pix":
                              paymentType = "Pix";
                              break;
                            case "Boleto":
                              paymentType = (
                                <FormattedMessage id="payment.boleto.bancario" />
                              );
                              break;
                          }

                          return (
                            <Link
                              to={`/transactions/${order.number}/${order.storeId}/details`}
                              key={key}
                            >
                              <ul>
                                {status}
                                <li>{order.number}</li>
                                <li>
                                  {intl.formatNumber(
                                    // order.isMultiplePayments
                                    //   ? order.payment.amount
                                       order.amount,
                                    {
                                      style: "currency",
                                      currency: settings.isForeigner ? "BRL" : order.currency,
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                </li>
                                {/* {settings.isForeigner && (
                                  <li>
                                    <FormattedNumber
                                      value={
                                        order.isMultiplePayments
                                          ? order.foreignAmount
                                          : order.foreignAmount
                                      }
                                      style="currency"
                                      currency={currency}
                                    />
                                  </li>
                                )} */}
                                <li>{order.customerName}</li>
                                <li>
                                  {paymentType}{" "}
                                  {/* {order.payment.installments > 1 &&
                                    `(${order.payment.installments}x)`} */}
                                </li>
                                <li>{order.products}</li>
                                <li>{order.storeName}</li>
                                <li>{`${order!.dateTime
                                  .split(",")[0]
                                  .substr(0, 5)}, ${
                                  order!.dateTime.split(",")[1]
                                }`}</li>
                              </ul>
                            </Link>
                          );
                        })
                      : <MyLoader />}
                  </span>
                </indexStyled.TransactionsTable>
              </indexStyled.TableList>
              <indexStyled.PaginationWrapper>
                <Pagination
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  disableNextButton={Orders.length < 50 ? true : false}
                  pageIndexProcessing={pageIndexProcessing}
                  totalOrder={numberTotalOfOrders}
                  maxNumberOrderPerRequest={50}
                />
              </indexStyled.PaginationWrapper>
            </ContentBox>
          </indexStyled.RowListWithMarginBottom>
        )}
      </LayoutWrapper>
    </>
  );
}

export default Transactions;
